<script>
  import FaRocket from "svelte-icons/fa/FaRocket.svelte";
</script>

<div class="text-center animate__animated animate__fadeInDown">
  <h1
    class="text-3xl sm:text-4xl montserrat font-effect-anaglyph text-bold
      text-white mt-5">
    Mohamed Shadab
  </h1>
  <div
    class="text-2xl text-white flex space-x-3 items-center font-semibold
      justify-center mt-5">
    <div class="w-5 h-5">
      <FaRocket />
    </div>
    <div>Software Engineer</div>
  </div>
</div>
